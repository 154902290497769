.apiLogsPage {
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      margin: 0;
    }

    .clearButton {
      margin-left: 20px;
    }
  }

  .MuiTableContainer-root {
    margin-top: 20px;

    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            font-weight: bold;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableRow-root {
          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
      }
    }
  }
}
