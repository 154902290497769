.linksWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;

  .linkTitle {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--text-primary);
  }

  .linkWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .linkBox {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem;
      background: rgba(97, 218, 251, 0.1);
      border-radius: 8px;

      .linkName {
        font-weight: 500;
        color: var(--text-primary);
      }

      .linkValue {
        color: var(--text-secondary);
      }

      .deleteLink {
        margin-left: auto;
        cursor: pointer;
        color: var(--text-secondary);
        transition: color 0.2s ease;

        &:hover {
          color: var(--danger-color);
        }
      }
    }
  }

  .buttonBox {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;

    button {
      padding: 0.5rem 1rem;
      border-radius: 6px;
      font-weight: 500;
      transition: all 0.2s ease;

      &.cta {
        background: var(--accent-color);
        color: white;

        &:hover {
          background: var(--accent-color-hover);
        }

        &.danger {
          background: var(--danger-color);

          &:hover {
            background: var(--danger-color-hover);
          }
        }
      }
    }
  }

  :global(.MuiButton-root) {
    align-self: flex-start;
    background-color: rgba(97, 218, 251, 0.22);
    border-color: #61dafb;
    color: #c9c9c9;

    &:hover {
      background-color: rgba(97, 218, 251, 0.31);
    }
  }
}
