.edit-app-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  color: #c9c9c9;

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    gap: 1rem;
  }

  .edit-app-container {
    padding: 2rem;
    background-color: #1d2026;
    border: 1px solid rgba(97, 218, 251, 0.2);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;

      h1 {
        font-size: 1.8rem;
        color: #c9c9c9;
        margin: 0;
      }

      .app-image {
        width: 80px;
        height: 80px;
        border-radius: 12px;
        object-fit: cover;
        border: 1px solid rgba(97, 218, 251, 0.2);
      }
    }

    .form-section {
      margin: 1.5rem 0;

      h2 {
        font-size: 1.2rem;
        color: #c9c9c9;
        margin-bottom: 1rem;
      }

      > div {
        margin-bottom: 1rem;
      }

      .selector-field-container {
        margin-bottom: 1.5rem;

        .selectorField {
          margin-bottom: 0;
        }
      }

      .error-text {
        color: #ff6b6b;
        font-size: 0.75rem;
        margin: 3px 14px 0;
      }
    }

    .section-divider {
      margin: 2rem 0;
      background-color: rgba(97, 218, 251, 0.2);
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      margin-top: 2rem;
      padding-top: 1rem;
      border-top: 1px solid rgba(97, 218, 251, 0.2);
    }
  }
}

// Global styles for Material-UI buttons
:global {
  .MuiButton-root {
    &.MuiButton-contained {
      background-color: rgba(97, 218, 251, 0.22);
      border: 1px solid #61dafb;
      color: #c9c9c9;

      &:hover {
        background-color: rgba(97, 218, 251, 0.31);
      }

      &:disabled {
        background-color: rgba(97, 218, 251, 0.1);
        color: rgba(201, 201, 201, 0.5);
        border-color: rgba(97, 218, 251, 0.3);
      }
    }

    &.MuiButton-outlined {
      border: 1px solid #61dafb;
      color: #c9c9c9;

      &:hover {
        background-color: rgba(97, 218, 251, 0.1);
      }

      &:disabled {
        border-color: rgba(97, 218, 251, 0.3);
        color: rgba(201, 201, 201, 0.5);
      }
    }
  }
}