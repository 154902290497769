.selectorField {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin-bottom: 1rem;

  .label {
    display: block;
    font-size: clamp(13px, 2.5vw, 14px);
    line-height: 1.4;
    font-weight: 500;
    color: var(--text-primary, #c9c9c9);
    margin-bottom: 4px;
    transition: color 0.3s ease;
  }

  .selector {
    .selector-field {
      &__control {
        min-height: 44px;
        border-radius: 8px;
        border-color: rgba(97, 218, 251, 0.2);
        transition: all 0.3s ease;

        &:hover {
          border-color: rgba(97, 218, 251, 0.4);
        }

        &--is-focused {
          border-color: #61dafb !important;
          box-shadow: 0 0 0 1px rgba(97, 218, 251, 0.2) !important;
        }
      }

      &__value-container {
        padding: 2px 16px;
      }

      &__input {
        color: #c9c9c9 !important;
      }

      &__single-value {
        color: #c9c9c9;
      }

      &__multi-value {
        background-color: rgba(97, 218, 251, 0.1);
        border-radius: 4px;

        &__label {
          color: #c9c9c9;
        }

        &__remove {
          color: #c9c9c9;
          cursor: pointer;
          
          &:hover {
            background-color: rgba(97, 218, 251, 0.2);
            color: #fff;
          }
        }
      }

      &__menu {
        background-color: #1d2026;
        border: 1px solid rgba(97, 218, 251, 0.2);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        &-list {
          padding: 4px;
        }
      }

      &__option {
        color: #c9c9c9;
        cursor: pointer;
        transition: all 0.2s ease;

        &--is-focused {
          background-color: rgba(97, 218, 251, 0.1);
        }

        &--is-selected {
          background-color: rgba(97, 218, 251, 0.2);
        }

        &:active {
          background-color: rgba(97, 218, 251, 0.3);
        }
      }

      &__placeholder {
        color: rgba(201, 201, 201, 0.5);
      }
    }
  }
}
